var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { LanguageEnum, UserClient, } from "@/api/client";
import moment from "moment";
import i18n from "@/i18n";
let UserModule = class UserModule extends VuexModule {
    _client = new UserClient();
    state = {};
    async getUserOrganizationDetails() {
        try {
            this.state.userDetails = await this._client.getUserDetails();
            this.state.userFullName = `${this.state.userDetails.firstName} ${this.state.userDetails.lastName}`;
        }
        catch (error) {
            //
        }
        return this.state.userDetails;
    }
    async updateUserLanguage(language) {
        await this._client.updateApplicationLanguage({ language: language });
        await this.getUserOrganizationDetails();
        this.updateApplicationLanguage();
    }
    updateApplicationLanguage() {
        const language = this.state.userDetails?.applicationLanguage;
        if (language != undefined)
            i18n.global.locale.value = language;
        if (!language) {
            moment.locale(LanguageEnum.Pl);
        }
        else {
            moment.locale(language);
        }
    }
    clearUserData() {
        this.state.userDetails = undefined;
        this.state.userFullName = undefined;
    }
    hasRole(...roleNames) {
        return this.state.userDetails?.roles.some((v) => roleNames.some((vv) => v == vv.toUpperCase()));
    }
    async getUsers(search) {
        try {
            const result = await this._client.getUsersList({ search: search });
            return result.usersList;
        }
        catch (error) {
            //
        }
        return {};
    }
    async addUser(request) {
        await this._client.addUser(request);
    }
    async getByEmail(email) {
        try {
            const result = await this._client.getUser(email);
            return result.user;
        }
        catch (error) {
            //
        }
        return {};
    }
    async updateUser(request) {
        try {
            await this._client.updateUser(request);
        }
        catch (error) {
            //
        }
    }
};
__decorate([
    Action
], UserModule.prototype, "getUserOrganizationDetails", null);
__decorate([
    Action
], UserModule.prototype, "clearUserData", null);
__decorate([
    Action
], UserModule.prototype, "hasRole", null);
__decorate([
    Action
], UserModule.prototype, "getUsers", null);
__decorate([
    Action
], UserModule.prototype, "addUser", null);
__decorate([
    Action
], UserModule.prototype, "getByEmail", null);
__decorate([
    Action
], UserModule.prototype, "updateUser", null);
UserModule = __decorate([
    Module
], UserModule);
export const userModule = new UserModule({ store, name: "user" });
