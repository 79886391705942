var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { VuexModule, Module, Action } from "vuex-class-modules";
import store from "@/store";
import router from "@/router";
import { AuthClient, } from "@/api/client";
import { notifyProblems, notifySuccess } from "@/helper/notifyHelper";
import { userModule } from "@/store/modules/user";
import i18n from "@/i18n";
let AuthModule = class AuthModule extends VuexModule {
    _client = new AuthClient();
    state = {
        isLoggedIn: false,
        roles: [],
    };
    async login(value) {
        try {
            await this._client.authenticate(value);
            await authModule.loadRoles();
            this.state.isLoggedIn = true;
        }
        catch (error) {
            if (error.status === 401) {
                notifyProblems(i18n.global.t("exception.incorrectEmailOrPassword"));
            }
            else {
                notifyProblems(i18n.global.t("exception.unknownError"));
            }
            this.state.isLoggedIn = false;
            return;
        }
        await userModule.getUserOrganizationDetails();
        userModule.updateApplicationLanguage();
    }
    async validateToken() {
        try {
            await this._client.validateToken();
            if (authModule.state.roles.length === 0) {
                await authModule.loadRoles();
            }
            this.state.isLoggedIn = true;
        }
        catch (error) {
            this.state.isLoggedIn = false;
        }
    }
    async forgotPassword(forgotPasswordRequest) {
        if (!forgotPasswordRequest) {
            return null;
        }
        try {
            const result = await this._client.forgotPasword(forgotPasswordRequest);
            notifySuccess("forgotPassword.successRequest");
            return result;
        }
        catch (error) {
            //
        }
        return null;
    }
    async logout() {
        try {
            await this._client.logout();
        }
        catch (error) {
            //
        }
        finally {
            // extremely picky about it's position - has to be first
            await router.push({ path: "/" });
            userModule.clearUserData();
            this.state.isLoggedIn = false;
        }
    }
    async changePassword(changePasswordRequest) {
        if (!changePasswordRequest) {
            return null;
        }
        try {
            const result = await this._client.changePassword(changePasswordRequest);
            notifySuccess("user.changePassword.success");
            return result;
        }
        catch (error) {
            //
        }
        return null;
    }
    async resetPassword(resetPassword) {
        if (!resetPassword) {
            return null;
        }
        try {
            const result = await this._client.forgotPasswordChange(resetPassword);
            notifySuccess("user.changePassword.success");
            return result;
        }
        catch (error) {
            //
        }
        return null;
    }
    async loadRoles() {
        try {
            const response = await this._client.getRoles();
            this.state.roles = response.userRoles;
        }
        catch (error) {
            //
        }
    }
    get hasPermission() {
        return (value) => {
            if (value) {
                return value.some((r) => this.state.roles.includes(r));
            }
            return false;
        };
    }
};
__decorate([
    Action
], AuthModule.prototype, "login", null);
__decorate([
    Action
], AuthModule.prototype, "validateToken", null);
__decorate([
    Action
], AuthModule.prototype, "forgotPassword", null);
__decorate([
    Action
], AuthModule.prototype, "logout", null);
__decorate([
    Action
], AuthModule.prototype, "changePassword", null);
__decorate([
    Action
], AuthModule.prototype, "resetPassword", null);
__decorate([
    Action
], AuthModule.prototype, "loadRoles", null);
AuthModule = __decorate([
    Module
], AuthModule);
export const authModule = new AuthModule({ store, name: "auth" });
// changed, now it will use route guards to perform redirects
// before refreshing the page always redirected to home page
// do not trigger until router is ready
router.isReady().then(() => {
    authModule.$watch((m) => m.state.isLoggedIn, async (is, was) => {
        if (was != is) {
            if (!is) {
                authModule.state.roles = [];
            }
            if (is && authModule.state.roles.length === 0) {
                await authModule.loadRoles();
            }
            // force push current route (with all it's params)
            // beforeEach() route guard will determine whether relog is necessary, keeping this path for redirect on login
            const route = Object.assign({ force: true }, router.currentRoute.value);
            router.push(route);
        }
    }, { deep: false, immediate: true });
});
