import i18n from "@/i18n";
const t = i18n.global.t;
export default class DealerDataTable {
    static getColumns() {
        return [
            {
                name: "dealerCode",
                align: "center",
                label: t("dealer.dealerCode"),
                field: "dealerCode",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "dealerName",
                align: "center",
                label: t("dealer.dealerName"),
                field: "dealerName",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            // {
            //   name: "details",
            //   align: "left",
            //   label: "",
            //   field: "details",
            //   format: (val: string): string => `${val ?? ""}`,
            //   sortable: false,
            //   isBoolean: false,
            //   style: "width: 10%",
            //   headerStyle: "width: 10%",
            // },
        ];
    }
}
