import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "full-width" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_skeleton = _resolveComponent("q-skeleton");
    const _component_q_td = _resolveComponent("q-td");
    const _component_q_tr = _resolveComponent("q-tr");
    const _component_q_markup_table = _resolveComponent("q-markup-table");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_q_markup_table, { flat: "" }, {
            default: _withCtx(() => [
                _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (n) => {
                        return (_openBlock(), _createBlock(_component_q_tr, {
                            key: n,
                            height: "56px"
                        }, {
                            default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.columns, (a) => {
                                    return (_openBlock(), _createBlock(_component_q_td, {
                                        class: "text-center bg-white",
                                        style: { "min-height": "50px" },
                                        key: a
                                    }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_skeleton, {
                                                style: { "margin": "0 auto", "min-height": "40px" },
                                                animation: "wave",
                                                type: "text",
                                                width: "100%",
                                                height: "100%"
                                            })
                                        ]),
                                        _: 2
                                    }, 1024));
                                }), 128))
                            ]),
                            _: 2
                        }, 1024));
                    }), 128))
                ])
            ]),
            _: 1
        })
    ]));
}
