var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { DealerClient } from "@/api/client";
import { notifyProblems, notifySuccess } from "@/helper/notifyHelper";
let DealerModule = class DealerModule extends VuexModule {
    _client = new DealerClient();
    async getDealers(search) {
        try {
            const result = await this._client.getDealersList(search);
            return result.dealersList;
        }
        catch (error) {
            //
        }
        return {};
    }
    async getByCode(code) {
        try {
            const result = await this._client.getDealerByCode(code);
            return result.dealer;
        }
        catch (error) {
            //
        }
        return {};
    }
    async updateDealer(dealer) {
        try {
            await this._client.updateDealer(dealer);
            notifySuccess("dealer.editSuccess");
        }
        catch (error) {
            notifyProblems(error);
        }
    }
    async addDealer(dealer) {
        try {
            await this._client.addDealer(dealer);
            notifySuccess("dealer.addSuccess");
        }
        catch (error) {
            notifyProblems(error);
        }
    }
};
__decorate([
    Action
], DealerModule.prototype, "getDealers", null);
__decorate([
    Action
], DealerModule.prototype, "getByCode", null);
__decorate([
    Action
], DealerModule.prototype, "updateDealer", null);
__decorate([
    Action
], DealerModule.prototype, "addDealer", null);
DealerModule = __decorate([
    Module
], DealerModule);
export const dealerModule = new DealerModule({ store, name: "dealer" });
