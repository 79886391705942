import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import _imports_0 from '@/assets/man_logo_batch.svg';
const _hoisted_1 = { class: "main-content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_route_tab = _resolveComponent("q-route-tab");
    const _component_q_tabs = _resolveComponent("q-tabs");
    const _component_q_toolbar_title = _resolveComponent("q-toolbar-title");
    const _component_User = _resolveComponent("User");
    const _component_q_toolbar = _resolveComponent("q-toolbar");
    const _component_q_header = _resolveComponent("q-header");
    const _component_router_view = _resolveComponent("router-view");
    const _component_q_page_container = _resolveComponent("q-page-container");
    const _component_Footer = _resolveComponent("Footer");
    const _component_q_layout = _resolveComponent("q-layout");
    const _directive_permission = _resolveDirective("permission");
    return (_openBlock(), _createBlock(_component_q_layout, { view: "hHh lpR fFf" }, {
        default: _withCtx(() => [
            (_ctx.loggedIn)
                ? (_openBlock(), _createBlock(_component_q_header, { key: 0 }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_toolbar, { class: "bg-white text-secondary toolbar" }, {
                            default: _withCtx(() => [
                                _createVNode(_component_q_toolbar_title, null, {
                                    default: _withCtx(() => [
                                        _createVNode(_component_q_tabs, {
                                            align: "left",
                                            "active-color": "primary"
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_route_tab, {
                                                    replace: "",
                                                    to: { name: 'parts' },
                                                    label: _ctx.$t(`menu.parts`)
                                                }, null, 8, ["label"]),
                                                _createVNode(_component_q_route_tab, {
                                                    replace: "",
                                                    to: { name: 'dealers' },
                                                    label: _ctx.$t(`menu.dealers`)
                                                }, null, 8, ["label"]),
                                                _withDirectives(_createVNode(_component_q_route_tab, {
                                                    replace: "",
                                                    to: { name: 'sources' },
                                                    label: _ctx.$t(`menu.sources`)
                                                }, null, 8, ["label"]), [
                                                    [_directive_permission, { permissions: _ctx.adminAndManager() }]
                                                ]),
                                                _createVNode(_component_q_route_tab, {
                                                    replace: "",
                                                    to: { name: 'settings' },
                                                    label: _ctx.$t(`menu.settings`)
                                                }, null, 8, ["label"]),
                                                _withDirectives(_createVNode(_component_q_route_tab, {
                                                    replace: "",
                                                    to: { name: 'users' },
                                                    label: _ctx.$t(`menu.users`)
                                                }, null, 8, ["label"]), [
                                                    [_directive_permission, { permissions: _ctx.adminAndManager() }]
                                                ])
                                            ]),
                                            _: 1
                                        })
                                    ]),
                                    _: 1
                                }),
                                _createVNode(_component_User),
                                _createElementVNode("img", {
                                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$router.push({ path: '/' }))),
                                    src: _imports_0,
                                    class: "cursor-pointer"
                                })
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                }))
                : _createCommentVNode("", true),
            _createVNode(_component_q_page_container, null, {
                default: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_router_view)
                    ])
                ]),
                _: 1
            }),
            _createVNode(_component_Footer)
        ]),
        _: 1
    }));
}
