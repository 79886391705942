import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_select = _resolveComponent("q-select");
    return (_openBlock(), _createBlock(_component_q_select, {
        class: "item-select",
        modelValue: _ctx.language,
        "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event) => ((_ctx.language) = $event)),
            _ctx.update
        ],
        outlined: "",
        square: "",
        dense: "",
        color: "anthracite",
        options: _ctx.languages,
        "display-value": _ctx.$t(`settings.lang.${_ctx.language}`),
        "option-label": (x) => _ctx.$t(`settings.lang.${x}`)
    }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "display-value", "option-label"]));
}
