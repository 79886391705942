import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
    class: "absolute",
    style: { "top": "1em", "right": "1em" }
};
const _hoisted_2 = { class: "text-h6" };
const _hoisted_3 = {
    key: 0,
    class: "absolute-full",
    style: { "background": "rgba(0, 0, 0, 0.15)" }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_card_section = _resolveComponent("q-card-section");
    const _component_q_form = _resolveComponent("q-form");
    const _component_q_spinner_oval = _resolveComponent("q-spinner-oval");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_dialog = _resolveComponent("q-dialog");
    return (_openBlock(), _createBlock(_component_q_dialog, {
        "model-value": _ctx.shown,
        persistent: "",
        onClose: _cache[0] || (_cache[0] = ($event) => (_ctx.close()))
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_card, { style: { "min-width": "600px", "max-width": "1400px" } }, {
                default: _withCtx(() => [
                    _createVNode(_component_q_form, {
                        ref: "form",
                        onSubmit: _withModifiers(_ctx.submit, ["prevent"])
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_card_section, null, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", _hoisted_1, [
                                        _createVNode(_component_q_btn, {
                                            round: "",
                                            size: "sm",
                                            color: "primary",
                                            disabled: _ctx.loading,
                                            onClick: _ctx.close
                                        }, {
                                            default: _withCtx(() => [
                                                _createVNode(_component_q_icon, { name: "mdi-close" })
                                            ]),
                                            _: 1
                                        }, 8, ["disabled", "onClick"])
                                    ]),
                                    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t(_ctx.title)), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_card_section, { class: "q-px-lg q-py-sm" }, {
                                default: _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "default")
                                ]),
                                _: 3
                            }),
                            _createVNode(_component_q_card_section, { class: "float-right" }, {
                                default: _withCtx(() => [
                                    _createElementVNode("div", null, [
                                        (!_ctx.hideSaveBtn)
                                            ? (_openBlock(), _createBlock(_component_q_btn, {
                                                key: 0,
                                                type: "submit",
                                                disabled: _ctx.loading,
                                                loading: _ctx.loading,
                                                color: "primary"
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t(_ctx.submitLabel)), 1)
                                                ]),
                                                _: 1
                                            }, 8, ["disabled", "loading"]))
                                            : _createCommentVNode("", true)
                                    ])
                                ]),
                                _: 1
                            })
                        ]),
                        _: 3
                    }, 8, ["onSubmit"]),
                    (_ctx.loading)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                            _createVNode(_component_q_spinner_oval, {
                                color: "primary",
                                size: "2em",
                                class: "absolute-center"
                            })
                        ]))
                        : _createCommentVNode("", true)
                ]),
                _: 3
            })
        ]),
        _: 3
    }, 8, ["model-value"]));
}
