var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { SourceClient, } from "@/api/client";
let SourcesModule = class SourcesModule extends VuexModule {
    _client = new SourceClient();
    async fetch(pagination) {
        try {
            const response = await this._client.getSources({ pagination: pagination });
            return response.sources;
        }
        catch (error) {
            console.error(error);
        }
        return {};
    }
    async addSource(request) {
        try {
            return await this._client.addSource(request);
        }
        catch (error) {
            console.error(error);
        }
        return { sourceId: 0 };
    }
    async updateSource(request) {
        try {
            return await this._client.updateSource(request);
        }
        catch (error) {
            console.error(error);
        }
        return { sourceId: 0 };
    }
    async getSource(request) {
        try {
            const response = await this._client.getDetails(request.id, request.errorsCount);
            return response.sourceDetails;
        }
        catch (error) {
            console.error(error);
        }
        return { sourceId: 0 };
    }
};
__decorate([
    Action
], SourcesModule.prototype, "fetch", null);
__decorate([
    Action
], SourcesModule.prototype, "addSource", null);
__decorate([
    Action
], SourcesModule.prototype, "updateSource", null);
__decorate([
    Action
], SourcesModule.prototype, "getSource", null);
SourcesModule = __decorate([
    Module
], SourcesModule);
export const sourcesModule = new SourcesModule({ store, name: "sources" });
