import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_file = _resolveComponent("q-file");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_q_btn, {
            onClick: _ctx.open,
            "full-width": "",
            unelevated: "",
            color: "primary",
            loading: _ctx.loading,
            label: _ctx.$t('sources.uploadFile')
        }, null, 8, ["onClick", "loading", "label"]),
        _createVNode(_component_q_file, {
            class: "file",
            ref: "file",
            label: _ctx.$t('sources.uploadFile'),
            "onUpdate:modelValue": _ctx.send,
            accept: ".csv"
        }, null, 8, ["label", "onUpdate:modelValue"])
    ], 64));
}
