var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Action, Module, VuexModule } from "vuex-class-modules";
import store from "@/store";
import { AdminClient } from "@/api/client";
import { notifyProblems, notifySuccess } from "@/helper/notifyHelper";
let AdminModule = class AdminModule extends VuexModule {
    _client = new AdminClient();
    state = {};
    async getSettings() {
        try {
            const response = await this._client.getAppSettings();
            return response.applicationSettings;
        }
        catch (error) {
            //
        }
        return {};
    }
    async updateSettings(value) {
        try {
            await this._client.updateAppSettings({ applicationSettings: value });
            notifySuccess("settings.success");
        }
        catch (error) {
            notifyProblems(error);
        }
    }
    async loadFolders() {
        try {
            const response = await this._client.getMailFolders();
            return response.folders;
        }
        catch (error) {
            //
        }
        return [];
    }
};
__decorate([
    Action
], AdminModule.prototype, "getSettings", null);
__decorate([
    Action
], AdminModule.prototype, "updateSettings", null);
__decorate([
    Action
], AdminModule.prototype, "loadFolders", null);
AdminModule = __decorate([
    Module
], AdminModule);
export const adminModule = new AdminModule({ store, name: "admin" });
