import { notifyProblems } from "@/helper/notifyHelper";
/* eslint-disable */
export class BaseClient {
    transformOptions(options) {
        return Promise.resolve(options);
    }
    async transformResult(url, response, processor) {
        try {
            const result = await processor(response);
            return result;
        }
        catch (error) {
            notifyProblems(error);
            throw error;
        }
    }
}
