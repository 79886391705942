import Notify from 'quasar/src/plugins/Notify.js';
;
import i18n from "@/i18n";
const getOptions = () => {
    return {
        type: "negative",
        classes: "notification",
        icon: "none",
        actions: [{ icon: "close", color: "white" }],
        position: "top-right",
    };
};
export const notifyProblems = (value, asHtml = false, persistent = false) => {
    // customize appearance
    const notifyOptions = getOptions();
    notifyOptions.message = typeof value === "string" ? value : "";
    notifyOptions.html = typeof value === "string" && asHtml;
    if (isInstanceOfProblemDetails(value)) {
        const problem = value;
        notifyOptions.message = i18n.global.t(problem.localizedTitle ?? problem.title ?? "");
    }
    else if (isInstanceOfApiException(value)) {
        const apiException = value;
        if (apiException.status == 401) {
            // bailout of notify
            return;
        }
        notifyOptions.message = apiException.message;
    }
    else if (value instanceof Object) {
        notifyOptions.message = JSON.stringify(value);
    }
    return Notify.create(notifyOptions);
};
export const isInstanceOfProblemDetails = (object) => {
    return ["type", "instance"].every((x) => x in object);
};
export const isInstanceOfApiException = (object) => {
    return ["message", "isApiException"].every((x) => x in object) && object["isApiException"];
};
export const notifySuccess = (value, asHtml = false, persistent = false) => {
    // customize appearance
    const notifyOptions = getOptions();
    notifyOptions.type = "positive";
    notifyOptions.message = typeof value === "string" ? value : "";
    notifyOptions.message = i18n.global.t(notifyOptions.message);
    notifyOptions.html = typeof value === "string" && asHtml;
    return Notify.create(notifyOptions);
};
