import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-6f404c0d"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "text-right text-caption q-mr-sm" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_footer = _resolveComponent("q-footer");
    return (_openBlock(), _createBlock(_component_q_footer, { class: "footer" }, {
        default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.version), 1)
        ]),
        _: 1
    }));
}
