import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "text-bold text-primary" };
const _hoisted_2 = { class: "q-mt-md" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_card = _resolveComponent("q-card");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_q_card, {
            class: _normalizeClass(_ctx.$q.screen.lt.md ? '' : 'card-wrapper')
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.title), 1),
                _createElementVNode("div", _hoisted_2, [
                    _renderSlot(_ctx.$slots, "default")
                ])
            ]),
            _: 3
        }, 8, ["class"])
    ]));
}
