import i18n from "@/i18n";
const t = i18n.global.t;
export default class DealerDataTable {
    static getColumns() {
        return [
            {
                name: "email",
                align: "center",
                label: t("user.email"),
                field: "email",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "lastName",
                align: "center",
                label: t("user.lastName"),
                field: "lastName",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
            {
                name: "firstName",
                align: "center",
                label: t("user.firstName"),
                field: "firstName",
                format: (val) => `${val ?? ""}`,
                sortable: true,
                isBoolean: false,
                style: "width: 10%",
                headerStyle: "width: 10%",
            },
        ];
    }
}
