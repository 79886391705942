import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-12" };
const _hoisted_3 = { class: "label man-text-input" };
const _hoisted_4 = { class: "col-12" };
const _hoisted_5 = { class: "label man-text-input" };
const _hoisted_6 = { class: "col-12" };
const _hoisted_7 = { class: "label man-text-input" };
const _hoisted_8 = { class: "col-12" };
const _hoisted_9 = { class: "label man-text-input" };
const _hoisted_10 = { class: "col-12" };
const _hoisted_11 = { class: "label man-text-input" };
const _hoisted_12 = { class: "col-12" };
const _hoisted_13 = { class: "label man-text-input" };
const _hoisted_14 = { class: "col-12" };
const _hoisted_15 = { class: "label man-text-input" };
const _hoisted_16 = { class: "col-12" };
const _hoisted_17 = { class: "label man-text-input" };
const _hoisted_18 = { class: "col-12" };
const _hoisted_19 = { class: "label man-text-input" };
const _hoisted_20 = { class: "col-12" };
const _hoisted_21 = { class: "label man-text-input" };
const _hoisted_22 = { class: "col-12" };
const _hoisted_23 = { class: "label man-text-input" };
const _hoisted_24 = { class: "col-12" };
const _hoisted_25 = { class: "label man-text-input" };
const _hoisted_26 = { class: "col-12 text-primary text-right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_form = _resolveComponent("q-form");
    return (_ctx.modelValue)
        ? (_openBlock(), _createBlock(_component_q_form, {
            key: 0,
            greedy: "",
            ref: "form",
            onSubmit: _ctx.action,
            onValidationSuccess: _cache[13] || (_cache[13] = ($event) => (_ctx.$emit('action')))
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`dealer.dealerCode`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.dealerCode,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.modelValue.dealerCode) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`dealer.dealerName`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.dealerName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.modelValue.dealerName) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(`dealer.emailCentral`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.emailCentral,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.modelValue.emailCentral) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            color: "anthracite"
                        }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`dealer.phoneCentral`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.phoneCentral,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event) => ((_ctx.modelValue.phoneCentral) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            color: "anthracite"
                        }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                        _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t(`dealer.emailParts`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.emailParts,
                            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.modelValue.emailParts) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            color: "anthracite"
                        }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_12, [
                        _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t(`dealer.phoneParts`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.phoneParts,
                            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.modelValue.phoneParts) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            color: "anthracite"
                        }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_14, [
                        _createElementVNode("span", _hoisted_15, _toDisplayString(_ctx.$t(`dealer.homepage`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.homepage,
                            "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.modelValue.homepage) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            color: "anthracite"
                        }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_16, [
                        _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t(`dealer.address.city`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.address.city,
                            "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event) => ((_ctx.modelValue.address.city) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_18, [
                        _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t(`dealer.address.postalCode`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.address.postalCode,
                            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event) => ((_ctx.modelValue.address.postalCode) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_20, [
                        _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.$t(`dealer.address.street`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.address.street,
                            "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event) => ((_ctx.modelValue.address.street) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.$t(`dealer.address.streetNumber`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.address.streetNumber,
                            "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event) => ((_ctx.modelValue.address.streetNumber) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_24, [
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t(`dealer.address.localNumber`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            style: { "margin-bottom": "20px" },
                            modelValue: _ctx.modelValue.address.localNumber,
                            "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event) => ((_ctx.modelValue.address.localNumber) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            color: "anthracite"
                        }, null, 8, ["modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_26, [
                        _createVNode(_component_q_btn, {
                            label: _ctx.$t(_ctx.closeLabel),
                            flat: "",
                            onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.$emit('cancel')))
                        }, null, 8, ["label"]),
                        _createVNode(_component_q_btn, {
                            label: _ctx.$t(_ctx.actionLabel),
                            unelevated: "",
                            type: "submit",
                            color: "primary"
                        }, null, 8, ["label"])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["onSubmit"]))
        : _createCommentVNode("", true);
}
