import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_btn = _resolveComponent("q-btn");
    return (_openBlock(), _createBlock(_component_q_btn, {
        flat: "",
        color: "primary",
        icon: "logout",
        onClick: _ctx.logout
    }, null, 8, ["onClick"]));
}
