import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { key: 0 };
const _hoisted_2 = { class: "row" };
const _hoisted_3 = { class: "col-12 text-center q-pa-lg" };
const _hoisted_4 = { class: "col-12 text-center" };
const _hoisted_5 = {
    key: 1,
    class: "edit"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AddUser = _resolveComponent("AddUser");
    const _component_q_item_label = _resolveComponent("q-item-label");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_EditUser = _resolveComponent("EditUser");
    const _component_q_list = _resolveComponent("q-list");
    const _component_MainComponent = _resolveComponent("MainComponent");
    return (_openBlock(), _createBlock(_component_MainComponent, null, {
        default: _withCtx(() => [
            (!_ctx.model.user || _ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("div", _hoisted_3, [
                            _createElementVNode("span", null, _toDisplayString(_ctx.$t("user.notFound")), 1)
                        ]),
                        _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_AddUser)
                        ])
                    ])
                ]))
                : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createVNode(_component_q_list, { bordered: "" }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_label, {
                                class: "item-header",
                                header: ""
                            }, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("user.info")), 1)
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { class: "col-2" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("user.email")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("span", null, _toDisplayString(_ctx.model.user.email), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_separator, { spaced: "" }),
                            _createVNode(_component_q_item, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { class: "col-2" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("user.firstName")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("span", null, _toDisplayString(_ctx.model.user.firstName), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_separator, { spaced: "" }),
                            _createVNode(_component_q_item, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { class: "col-2" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("user.lastName")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("span", null, _toDisplayString(_ctx.model.user.lastName), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_separator, { spaced: "" }),
                            _createVNode(_component_q_item, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { class: "col-2" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t("user.role")), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_q_item_section, null, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createElementVNode("span", null, _toDisplayString(_ctx.$t("roles." + _ctx.model.user.role)), 1)
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_item_section, { class: "col-10" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label)
                                        ]),
                                        _: 1
                                    }),
                                    _createVNode(_component_q_item_section, { class: "col-2 text-right" }, {
                                        default: _withCtx(() => [
                                            _createVNode(_component_q_item_label, null, {
                                                default: _withCtx(() => [
                                                    _createVNode(_component_EditUser, {
                                                        modelValue: _ctx.model.user,
                                                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.model.user) = $event)),
                                                        onSuccess: _ctx.getData,
                                                        onNewCode: _cache[1] || (_cache[1] = ($event) => (_ctx.pushToNew($event)))
                                                    }, null, 8, ["modelValue", "onSuccess"])
                                                ]),
                                                _: 1
                                            })
                                        ]),
                                        _: 1
                                    })
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })
                ]))
        ]),
        _: 1
    }));
}
