import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = { class: "offset-8 col-4 text-right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_input = _resolveComponent("q-input");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_MainComponent = _resolveComponent("MainComponent");
    return (_openBlock(), _createBlock(_component_MainComponent, null, {
        default: _withCtx(() => [
            _createVNode(_component_DataTable, {
                id: "partsTable",
                loading: _ctx.loading,
                rows: _ctx.items,
                columns: _ctx.getColumns(),
                paginationProp: _ctx.pagination,
                "onUpdate:paginationProp": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                getData: _ctx.getData
            }, {
                top: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_q_input, {
                            color: "anthracite",
                            hint: _ctx.$t('hint.search'),
                            square: "",
                            dense: "",
                            outlined: "",
                            modelValue: _ctx.text,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.text) = $event)),
                            loading: _ctx.loading
                        }, {
                            append: _withCtx(() => [
                                (_ctx.text === '')
                                    ? (_openBlock(), _createBlock(_component_q_icon, {
                                        key: 0,
                                        name: "search"
                                    }))
                                    : (_openBlock(), _createBlock(_component_q_icon, {
                                        key: 1,
                                        name: "clear",
                                        class: "cursor-pointer",
                                        onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.text = ''))
                                    }))
                            ]),
                            _: 1
                        }, 8, ["hint", "modelValue", "loading"])
                    ])
                ]),
                _: 1
            }, 8, ["loading", "rows", "columns", "paginationProp", "getData"])
        ]),
        _: 1
    }));
}
