import "./styles/quasar.scss";
import "@/fonts/roboto-v30-latin-ext_latin-regular.eot";
import "@/fonts/roboto-v30-latin-ext_latin-regular.svg";
import "@/fonts/roboto-v30-latin-ext_latin-regular.ttf";
import "@/fonts/roboto-v30-latin-ext_latin-regular.woff";
import "@/fonts/roboto-v30-latin-ext_latin-regular.woff2";
import "@/fonts/ManEurope_Bold.ttf";
import "@/fonts/ManEurope_Regular.ttf";
import "@/fonts/ManEuropeCondensed_Regular.ttf";
import "@/fonts/ManEuropeCondensed_Bold.ttf";
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
import "@quasar/extras/material-icons-round/material-icons-round.css";
import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";
import "@quasar/extras/fontawesome-v5/fontawesome-v5.css";
import "@quasar/extras/mdi-v4/mdi-v4.css";
import Notify from 'quasar/src/plugins/Notify.js';
;
import MaterialIconSet from "quasar/icon-set/material-icons";
MaterialIconSet.table.arrowUp = "arrow_drop_down";
export default {
    config: {
        brand: {
            primary: "#E50045",
            secondary: "#303C49",
            tertiary: "#AFAFAF",
            info: "#3978B3",
            success: "#6B9801",
            positive: "#6B9801",
            warning: "#FF8F02",
            danger: "#C00042",
            negative: "#C00042",
        },
        screen: {
            bodyClasses: true,
        },
    },
    plugins: { Notify },
};
