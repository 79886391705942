import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "text-h6" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_card_section = _resolveComponent("q-card-section");
    const _component_UserForm = _resolveComponent("UserForm");
    const _component_q_card = _resolveComponent("q-card");
    const _component_q_dialog = _resolveComponent("q-dialog");
    const _component_q_btn = _resolveComponent("q-btn");
    return (_openBlock(), _createElementBlock(_Fragment, null, [
        _createVNode(_component_q_dialog, {
            modelValue: _ctx.dialog,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.dialog) = $event)),
            persistent: ""
        }, {
            default: _withCtx(() => [
                _createVNode(_component_q_card, { style: { "min-width": "350px" } }, {
                    default: _withCtx(() => [
                        _createVNode(_component_q_card_section, null, {
                            default: _withCtx(() => [
                                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t("user.edit")), 1)
                            ]),
                            _: 1
                        }),
                        _createVNode(_component_q_card_section, null, {
                            default: _withCtx(() => [
                                _createVNode(_component_UserForm, {
                                    modelValue: _ctx.modelValueCopy,
                                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.modelValueCopy) = $event)),
                                    "onChange:dialogState": _cache[1] || (_cache[1] = ($event) => (_ctx.dialog = $event)),
                                    onAction: _ctx.handleAction,
                                    onCancel: _ctx.cancel,
                                    "admin-change-password": true,
                                    password: _ctx.passwordModel,
                                    actionLabel: _ctx.actionLabel
                                }, null, 8, ["modelValue", "onAction", "onCancel", "password", "actionLabel"])
                            ]),
                            _: 1
                        })
                    ]),
                    _: 1
                })
            ]),
            _: 1
        }, 8, ["modelValue"]),
        _createVNode(_component_q_btn, {
            onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.dialog = true)),
            "full-width": "",
            unelevated: "",
            color: "primary",
            loading: _ctx.loading,
            label: _ctx.$t('user.edit')
        }, null, 8, ["loading", "label"])
    ], 64));
}
