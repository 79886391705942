import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_item_section = _resolveComponent("q-item-section");
    const _component_q_item = _resolveComponent("q-item");
    const _component_q_separator = _resolveComponent("q-separator");
    const _component_q_list = _resolveComponent("q-list");
    const _component_q_drawer = _resolveComponent("q-drawer");
    const _directive_ripple = _resolveDirective("ripple");
    return (_openBlock(), _createBlock(_component_q_drawer, {
        modelValue: _ctx.leftDrawerOpen,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.leftDrawerOpen) = $event)),
        "show-if-above": "",
        bordered: "",
        mini: !_ctx.loggedIn
    }, {
        default: _withCtx(() => [
            _createVNode(_component_q_list, { class: "menu" }, {
                default: _withCtx(() => [
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        avatar: "",
                        to: "/"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        color: "primary",
                                        name: "home"
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("menu.home")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })), [
                        [_directive_ripple]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        avatar: "",
                        to: "/settings"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        color: "primary",
                                        name: "settings"
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("menu.settings")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })), [
                        [_directive_ripple]
                    ]),
                    _createVNode(_component_q_separator),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        avatar: "",
                        to: "/users"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        color: "primary",
                                        name: "people"
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("menu.users")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })), [
                        [_directive_ripple]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        avatar: "",
                        to: "/dealers"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        color: "primary",
                                        name: "people"
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("menu.dealers")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })), [
                        [_directive_ripple]
                    ]),
                    _withDirectives((_openBlock(), _createBlock(_component_q_item, {
                        clickable: "",
                        avatar: "",
                        to: "/sources"
                    }, {
                        default: _withCtx(() => [
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createVNode(_component_q_icon, {
                                        color: "primary",
                                        name: "backup_table"
                                    })
                                ]),
                                _: 1
                            }),
                            _createVNode(_component_q_item_section, null, {
                                default: _withCtx(() => [
                                    _createTextVNode(_toDisplayString(_ctx.$t("menu.sources")), 1)
                                ]),
                                _: 1
                            })
                        ]),
                        _: 1
                    })), [
                        [_directive_ripple]
                    ])
                ]),
                _: 1
            })
        ]),
        _: 1
    }, 8, ["modelValue", "mini"]));
}
