import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, withCtx as _withCtx } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_icon = _resolveComponent("q-icon");
    const _component_q_input = _resolveComponent("q-input");
    return (_openBlock(), _createBlock(_component_q_input, {
        "onUpdate:modelValue": [
            _ctx.goToParts,
            _cache[1] || (_cache[1] = ($event) => ((_ctx.text) = $event))
        ],
        dark: "",
        dense: "",
        standout: "",
        modelValue: _ctx.text,
        class: "q-ml-md"
    }, {
        append: _withCtx(() => [
            (_ctx.text === '')
                ? (_openBlock(), _createBlock(_component_q_icon, {
                    key: 0,
                    name: "search"
                }))
                : (_openBlock(), _createBlock(_component_q_icon, {
                    key: 1,
                    name: "clear",
                    class: "cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.text = ''))
                }))
        ]),
        _: 1
    }, 8, ["onUpdate:modelValue", "modelValue"]));
}
