import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "row" };
const _hoisted_2 = { class: "col-12" };
const _hoisted_3 = { class: "label man-text-input" };
const _hoisted_4 = { class: "col-12" };
const _hoisted_5 = { class: "label man-text-input" };
const _hoisted_6 = { class: "col-12" };
const _hoisted_7 = { class: "label man-text-input" };
const _hoisted_8 = { class: "col-12" };
const _hoisted_9 = { class: "label man-text-input" };
const _hoisted_10 = {
    key: 0,
    class: "col-12"
};
const _hoisted_11 = { class: "label man-text-input" };
const _hoisted_12 = {
    key: 1,
    class: "col-12"
};
const _hoisted_13 = { class: "label man-text-input" };
const _hoisted_14 = { class: "col-12 text-primary text-right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_q_input = _resolveComponent("q-input");
    const _component_q_select = _resolveComponent("q-select");
    const _component_q_btn = _resolveComponent("q-btn");
    const _component_q_form = _resolveComponent("q-form");
    return (_ctx.modelValue)
        ? (_openBlock(), _createBlock(_component_q_form, {
            key: 0,
            greedy: "",
            ref: "form",
            onSubmit: _ctx.action,
            onValidationSuccess: _cache[7] || (_cache[7] = ($event) => (_ctx.$emit('action'))),
            autocomplete: "off"
        }, {
            default: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                    _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t(`user.email`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.email,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.modelValue.email) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required, _ctx.email],
                            color: "anthracite",
                            autocomplete: "off"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t(`user.firstName`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.firstName,
                            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.modelValue.firstName) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite",
                            autocomplete: "off"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.$t(`user.lastName`)), 1),
                        _createVNode(_component_q_input, {
                            dense: "",
                            "no-error-icon": "",
                            square: "",
                            outlined: "",
                            modelValue: _ctx.modelValue.lastName,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event) => ((_ctx.modelValue.lastName) = $event)),
                            modelModifiers: { trim: true },
                            "lazy-rules": "",
                            rules: [_ctx.required],
                            color: "anthracite",
                            autocomplete: "off"
                        }, null, 8, ["modelValue", "rules"])
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                        _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.$t(`user.role`)), 1),
                        _createVNode(_component_q_select, {
                            class: "item-select full-width",
                            modelValue: _ctx.role,
                            "onUpdate:modelValue": [
                                _cache[3] || (_cache[3] = ($event) => ((_ctx.role) = $event)),
                                _ctx.onRoleChanged
                            ],
                            outlined: "",
                            square: "",
                            dense: "",
                            "emit-value": "",
                            color: "anthracite",
                            options: _ctx.roles,
                            "display-value": !_ctx.role ? '' : _ctx.$t(`roles.${_ctx.role.toLowerCase()}`),
                            "option-label": (x) => _ctx.$t(`roles.${x.label}`),
                            autocomplete: "off"
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "options", "display-value", "option-label"])
                    ]),
                    (_ctx.password)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                            _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.$t(`user.password`)), 1),
                            _createVNode(_component_q_input, {
                                ref: "refPwd",
                                dense: "",
                                "no-error-icon": "",
                                square: "",
                                outlined: "",
                                type: "password",
                                modelValue: _ctx.password.password,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event) => ((_ctx.password.password) = $event)),
                                modelModifiers: { trim: true },
                                "lazy-rules": "",
                                rules: [
                                    _ctx.adminChangePassword ? '' : _ctx.required,
                                    (val) => !val || (val.length >= 8 && val.length <= 64) || _ctx.$t('rules.incorrectLength'),
                                    (val) => !val || [...val].some((a) => a.match(/[A-ZĄĆĘŁŃÓŚŹŻ]/)) || _ctx.$t('rules.atLeastOneUpperCase'),
                                    (val) => !val || [...val].some((a) => a.match(/[a-ząćęłńóśźż]/)) || _ctx.$t('rules.atLeastOneLowerCase'),
                                ],
                                color: "anthracite",
                                autocomplete: "new-password"
                            }, null, 8, ["modelValue", "rules"])
                        ]))
                        : _createCommentVNode("", true),
                    (_ctx.password)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                            _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.$t(`user.repeatPassword`)), 1),
                            _createVNode(_component_q_input, {
                                ref: "refPwdRpt",
                                dense: "",
                                "no-error-icon": "",
                                square: "",
                                outlined: "",
                                type: "password",
                                modelValue: _ctx.password.repeatPassword,
                                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event) => ((_ctx.password.repeatPassword) = $event)),
                                modelModifiers: { trim: true },
                                "lazy-rules": "",
                                rules: [
                                    _ctx.adminChangePassword ? '' : _ctx.required,
                                    (val) => !val || _ctx.password.repeatPassword === _ctx.password.password || _ctx.$t('user.add.passwordNotMatch'),
                                ],
                                color: "anthracite",
                                autocomplete: "off"
                            }, null, 8, ["modelValue", "rules"])
                        ]))
                        : _createCommentVNode("", true),
                    _createElementVNode("div", _hoisted_14, [
                        _createVNode(_component_q_btn, {
                            label: _ctx.$t(_ctx.closeLabel),
                            flat: "",
                            onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('cancel')))
                        }, null, 8, ["label"]),
                        _createVNode(_component_q_btn, {
                            label: _ctx.$t(_ctx.actionLabel),
                            unelevated: "",
                            type: "submit",
                            color: "primary"
                        }, null, 8, ["label"])
                    ])
                ])
            ]),
            _: 1
        }, 8, ["onSubmit"]))
        : _createCommentVNode("", true);
}
