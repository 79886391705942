import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
const _hoisted_1 = { class: "offset-8 col-4 text-right" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_AddDealer = _resolveComponent("AddDealer");
    const _component_DataTable = _resolveComponent("DataTable");
    const _component_MainComponent = _resolveComponent("MainComponent");
    return (_openBlock(), _createBlock(_component_MainComponent, null, {
        default: _withCtx(() => [
            _createVNode(_component_DataTable, {
                rows: _ctx.listData,
                columns: _ctx.TableData.getColumns(),
                addAction: _ctx.addAction,
                "onUpdate:addAction": _cache[0] || (_cache[0] = ($event) => ((_ctx.addAction) = $event)),
                loading: _ctx.dataIsLoading,
                "onUpdate:loading": _cache[1] || (_cache[1] = ($event) => ((_ctx.dataIsLoading) = $event)),
                paginationProp: _ctx.pagination,
                "onUpdate:paginationProp": _cache[2] || (_cache[2] = ($event) => ((_ctx.pagination) = $event)),
                "details-action": _ctx.detailsAction,
                "onChange:searchQuery": _cache[3] || (_cache[3] = ($event) => (_ctx.searchQuery = $event)),
                getData: _ctx.getData,
                filterObject: _ctx.filter,
                "onUpdate:filterObject": _cache[4] || (_cache[4] = ($event) => ((_ctx.filter) = $event))
            }, {
                top: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, [
                        _createVNode(_component_AddDealer, { onAdded: _ctx.successAdd }, null, 8, ["onAdded"])
                    ])
                ]),
                _: 1
            }, 8, ["rows", "columns", "addAction", "loading", "paginationProp", "details-action", "getData", "filterObject"])
        ]),
        _: 1
    }));
}
