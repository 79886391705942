import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Login from "@/views/Login.vue";
import Parts from "@/views/Parts.vue";
import { authModule } from "@/store/modules/auth";
import Settings from "@/views/Settings.vue";
import { RoleEnum } from "@/api/client";
import DealerList from "@/views/dealer/DealerList.vue";
import DealerDetails from "@/views/dealer/DealerDetails.vue";
import Sources from "@/views/Sources.vue";
import SourceDetails from "@/views/SourceDetails.vue";
import UsersList from "@/views/users/UsersList.vue";
import UserDetails from "@/views/users/UserDetails.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import ResetPassword from "@/views/ResetPassword.vue";
const routes = [
    {
        path: "/",
        name: "home",
        component: Home,
        meta: {
            requiresAuth: true,
        },
        redirect: "/parts",
    },
    {
        path: "/parts",
        name: "parts",
        component: Parts,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: Login,
    },
    {
        path: "/forgotPassword",
        name: "forgotPassword",
        component: ForgotPassword,
    },
    {
        path: "/resetPassword",
        name: "resetPassword",
        component: ResetPassword,
    },
    {
        path: "/settings",
        name: "settings",
        component: Settings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/users",
        name: "users",
        component: UsersList,
        meta: {
            requiresAuth: true,
            permissions: [RoleEnum.Administrator, RoleEnum.Manager],
        },
    },
    {
        path: "/users/:email",
        name: "userDetails",
        component: UserDetails,
        meta: {
            requiresAuth: true,
            permissions: [RoleEnum.Administrator, RoleEnum.Manager],
        },
    },
    {
        path: "/dealers",
        name: "dealers",
        component: DealerList,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/dealers/:code",
        name: "dealerDetails",
        component: DealerDetails,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/sources",
        name: "sources",
        component: Sources,
        meta: {
            requiresAuth: true,
            permissions: [RoleEnum.Administrator],
        },
    },
    {
        path: "/sources/:id",
        name: "sourceDetails",
        component: SourceDetails,
        meta: {
            requiresAuth: true,
            permissions: [RoleEnum.Administrator],
        },
    },
];
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});
router.beforeEach((to, from, next) => {
    const isLoggedIn = authModule.state.isLoggedIn;
    if (to.name == "login" && isLoggedIn) {
        let redirect = null;
        if (typeof to.params.redirect === "string")
            redirect = router.resolve(to.params.redirect);
        if (redirect)
            next(redirect);
        else
            next({ name: "home" });
    }
    else {
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (!isLoggedIn) {
                next({ name: "login", params: { redirect: to.fullPath } });
            }
            else {
                if (to.meta.permissions) {
                    if (authModule.hasPermission(to.meta.permissions)) {
                        next();
                    }
                    else {
                        next({ name: "home" });
                    }
                }
                else {
                    next();
                }
            }
        }
        else {
            next();
        }
    }
});
export default router;
